import moment, * as moments from 'moment';
import React, { useState, useEffect } from "react";
import { pieOptions, splineOptions } from "../../data/paymentpage";
import ChartGraph from "../CanvasJS";

const Payment = () => {
  const token = localStorage.getItem("jwt_access_token");
  let paymentUrl = `/payments/getAll`;
  const [paymentData, setPaymentData] = useState([]);

  useEffect(() => {
    getPaymentsAndStatus()
  }, [])

  const getPaymentsAndStatus = () => {
    var requestOptions = {
      method: "get",
      headers: { Authorization: `Bearer ${token}` },
    };
    fetch(process.env.REACT_APP_BACKEND_BASE_URL_AFTER_LOGIN + paymentUrl, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.status) {
          setPaymentData(res.data);
        }
      });
  }
  return (
    <>
      <div className="min-h-screen bg-grey">
        <div className="bg-grey min-h-screen">
          {/* <Header /> */}
          <div className="col_11 mx-auto">
            <div className="row py-4 items-center">
              <div className="col">
                <h2 className="text-xl font-bold">{"Payment & Stats"}</h2>
              </div>
            </div>
            {/*payment and stats */}
            <div className="py-4">
              <div className="card--graph">
                <table style={{ width: "100%", font: "normal normal bold 14px Manrope", color: "#242424" }}>
                  <tbody>
                    <tr style={{ textAlign: "left", height: "40px" }}>
                      <th style={{ textAlign: "center" }}>Monthly</th>
                      <th>Total Billing</th>
                      <th>Payment Received</th>
                      <th>Next Payment Due</th>
                      <th>Due Date</th>
                    </tr>
                    {paymentData.length > 0 && paymentData.map((item, index) => {
                      let paymentReceived = 0;
                      let paymentReceivedCount = 0
                      let paymentPending = 0;
                      let lastDue = "";
                      item?.payments?.map((payment) => {
                        if (payment?.status == "Completed") {
                          paymentReceived = paymentReceived + (item?.totalAmount * payment?.percent / 100);
                          paymentReceivedCount = paymentReceivedCount + 1;
                        } else if (payment?.status == "Pending") {
                          paymentPending = paymentPending + (item?.totalAmount * payment?.percent / 100);
                          if (lastDue == "") {
                            lastDue = payment?.date
                          }
                        }
                      });
                      return (
                        <>
                          {index % 2 === 0 ?

                            <tr key={index} style={{ textAlign: "start", minHeight: "100px", border: "14px solid #F7F6FF", verticalAlign: "top", background: "#F7F6FF", font: "normal normal normal 14px Manrope", color: "#242424" }}>
                              <td style={{ textAlign: "center", paddingBottom: "10px", font: "normal normal bold 14px Manrope" }}>{item?.month}</td>
                              <td>
                                <span style={{ font: "normal normal bold 14px/20px Manrope" }} >{paymentReceived + paymentPending}</span><br />
                                <div style={{ display: "flex", gap: "2px", marginBottom: "5px" }}>
                                  {item?.payments.map(payment => (
                                    <>{(payment?.status == "Completed") ?
                                      <hr style={{ width: "18px", height: "4px", borderRadius: "20px", background: "#6E62E3" }} /> :
                                      <hr style={{ width: "18px", height: "4px", borderRadius: "20px", background: "#6E62E33B" }} />
                                    }</>
                                  ))}
                                </div>
                                <span style={{ font: "normal normal bold 14px/20px Manrope" }} >Break-up</span><br />
                                {item?.payments?.map((payment, index) => (
                                  <><span style={{ font: "normal normal 14px/20px Manrope" }} >Month {index + 1} {item?.totalAmount * payment?.percent / 100} ({payment?.percent}% of Total Payment)</span><br /></>
                                ))}
                              </td>
                              <td>Rs.{paymentReceived}</td>
                              <td>Rs.{paymentPending}</td>
                              <td>{lastDue && moment(lastDue).format("DD MMM' YYYY")}</td>
                            </tr> :

                            <tr style={{ textAlign: "start", minHeight: "100px", border: "14px solid white", verticalAlign: "top", background: "white", font: "normal normal normal 14px Manrope", color: "#242424" }}>
                              <td style={{ textAlign: "center", font: "normal normal bold 14px/30px Manrope" }}>{item?.month}</td>
                              <td>
                                <span style={{ font: "normal normal bold 14px/30px Manrope" }} >{paymentReceived + paymentPending}</span><br />
                                <div style={{ display: "flex", gap: "2px", marginBottom: "5px" }}>
                                {item?.payments.map(payment => (
                                    <>{(payment?.status == "Completed") ?
                                      <hr style={{ width: "18px", height: "4px", borderRadius: "20px", background: "#6E62E3" }} /> :
                                      <hr style={{ width: "18px", height: "4px", borderRadius: "20px", background: "#6E62E33B" }} />
                                    }</>
                                  ))}
                                </div>
                                <span style={{ font: "normal normal bold 14px/20px Manrope" }} >Break-up</span><br />
                                {item?.payments?.map((payment, index) => (
                                  <><span style={{ font: "normal normal 14px/20px Manrope" }} >Month {index + 1} {item?.totalAmount * payment?.percent / 100} ({payment?.percent}% of Total Payment)</span><br /></>
                                ))}
                              </td>
                              <td style={{ font: "normal normal normal 14px/30px Manrope" }} >Rs.{paymentReceived}</td>
                              <td style={{ font: "normal normal normal 14px/30px Manrope" }} >Rs.{paymentPending}</td>
                              <td style={{ font: "normal normal normal 14px/30px Manrope" }} >{lastDue && moment(lastDue).format("DD MMM' YYYY")}</td>
                            </tr>}
                        </>
                      )
                    })}

                  </tbody>
                </table>
              </div>
            </div>
            {/* Pie graph */}
            <div className="py-4">
              <div className="card--graph">
                <div>
                  <h2 className="text-lg font-bold">Statics</h2>
                </div>
                <div className="row py-4">
                  <div className="col_4">
                    <div style={{ marginTop: "50px" }}>
                      <div style={{ display: 'flex', font: "normal normal bold 22px/25px Manrope", justifyContent: "center" }}>
                        Total {10}L
                      </div>
                      <div style={{ display: 'flex', gap: "10px", justifyContent: "center", marginTop: "15px" }}>
                        <div style={{ width: "116px", textAlign: "center", height: "96px", background: "#6E62E3", borderRadius: "10px" }}>
                          <span style={{ font: "normal normal bold 12px/25px Manrope", color: "#FFFFFF" }}>Paid</span><br />
                          <span style={{ font: "normal normal bold 26px/25px Manrope", color: "#FFFFFF" }}>5</span>
                          <span style={{ font: "normal normal bold 11px/25px Manrope", color: "#FFFFFF" }}>lacs</span>
                          <br />
                          <span style={{ font: "normal normal bold 26px/25px Manrope", color: "#FFFFFF" }}>50%</span><br />
                        </div>
                        <div style={{ width: "111px", textAlign: "center", height: "96px", background: "#EDF0FA", borderRadius: "10px" }}>
                          <span style={{ font: "normal normal bold 12px/25px Manrope", color: "#6E62E3" }}>Pending</span><br />
                          <span style={{ font: "normal normal bold 26px/25px Manrope", color: "#6E62E3" }}>5</span>
                          <span style={{ font: "normal normal bold 11px/25px Manrope", color: "#6E62E3" }}>lacs</span>
                          <br />
                          <span style={{ font: "normal normal bold 26px/25px Manrope", color: "#6E62E3" }}>50%</span><br />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col">
                    <ChartGraph options={pieOptions} />
                  </div>
                </div>
              </div>
            </div>
            {/* Area graph */}
            <div className="py-4">
              <div className="card--graph">
                <div>
                  <h2 className="text-lg font-bold">Statics</h2>
                </div>
                <div className="row py-4">
                  <div className="col">
                    <ChartGraph options={splineOptions} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Payment;
