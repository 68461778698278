import { Form, Formik, FieldArray, ErrorMessage, Field } from "formik";
import React, { useEffect, useState } from "react";
import Button from "../../CommonComponents/Buttons";
import * as XLSX from "xlsx";
import { BsPencil } from "react-icons/bs";
import { AiOutlineDelete } from "react-icons/ai";
import { json, useLocation, useNavigate } from "react-router-dom";

const List = ({ index, remove, product }) => {
  const [editable, setEditable] = useState(false);

  return (
    <>
      <div className="items-center f_14" key={index}>
        <div style={{ display: "flex", borderBottom: "1px solid gray" }}>
          <div className="popupTable__data" style={{ minWidth: "200px" }}>
            <Field
              disabled={!editable}
              name={`products.${index}.productDisplayName`}
              type="text"
              value={product["ProductDisplayName"]}
            />
          </div>
          <div className="popupTable__data">
            <div className="h-16 w-16">
              <img src={product["Front Image"]} className="w-full h-full" />
            </div>
          </div>
          <div className="popupTable__data">
            <Field
              disabled={!editable}
              name={`products.${index}.SKU`}
              type="text"
              value={product["SKU"]}
            />
          </div>
          <div className="popupTable__data">
            <Field
              disabled={!editable}
              name={`products.${index}.Brand`}
              type="text"
              value={product["Brand"]}
            />
          </div>
          <div className="popupTable__data">
            <Field
              disabled={!editable}
              name={`products.${index}.Shape`}
              type="text"
              value={product["Shape"]}
            />
          </div>
          <div className="popupTable__data">
            <Field
              disabled={!editable}
              name={`products.${index}.MRP`}
              type="text"
              value={product["MRP"]}
            />
          </div>
          {product["Bust"] && (
            <div className="popupTable__data">
              <Field
                disabled={!editable}
                name={`products.${index}.Bust`}
                type="text"
                value={product["Bust"]}
              />
            </div>
          )}
          {product["Bust(in)"] && (
            <div className="popupTable__data">
              <Field
                disabled={!editable}
                name={`products.${index}.Bust(in)`}
                type="text"
                value={product["Bust(in)"]}
              />
            </div>
          )}
          {product["Waist"] && (
            <div className="popupTable__data">
              <Field
                disabled={!editable}
                name={`products.${index}.Waist`}
                type="text"
                value={product["Waist"]}
              />
            </div>
          )}
          {product["Chest"] && (
            <div className="popupTable__data">
              <Field
                disabled={!editable}
                name={`products.${index}.Chest`}
                type="text"
                value={product["Chest"]}
              />
            </div>
          )}
          {product["Chest(in)"] && (
            <div className="popupTable__data">
              <Field
                disabled={!editable}
                name={`products.${index}.Chest(in)`}
                type="text"
                value={product["Chest(in)"]}
              />
            </div>
          )}
          {product["Hips"] && (
            <div className="popupTable__data">
              <Field
                disabled={!editable}
                name={`products.${index}.Hips`}
                type="text"
                value={product["Hips"]}
              />
            </div>
          )}
          {product["Front Length"] && (
            <div className="popupTable__data">
              <Field
                disabled={!editable}
                name={`products.${index}.Front Length`}
                type="text"
                value={product["Front Length"]}
              />
            </div>
          )}
          {product["Across Shoulder"] && (
            <div className="popupTable__data">
              <Field
                disabled={!editable}
                name={`products.${index}.Across Shoulder`}
                type="text"
                value={product["Across Shoulder"]}
              />
            </div>
          )}

          {product["To Fit Bust"] && (
            <div className="popupTable__data">
              <Field
                disabled={!editable}
                name={`products.${index}.To Fit Bust`}
                type="text"
                value={product["To Fit Bust"]}
              />
            </div>
          )}
          {product["To Fit Hips"] && (
            <div className="popupTable__data">
              <Field
                disabled={!editable}
                name={`products.${index}.To Fit Hips`}
                type="text"
                value={product["To Fit Hips"]}
              />
            </div>
          )}
          {product["To Fit Waist"] && (
            <div className="popupTable__data">
              <Field
                disabled={!editable}
                name={`products.${index}.To Fit Waist`}
                type="text"
                value={product["To Fit Waist"]}
              />
            </div>
          )}
          {product["To Fit Chest"] && (
            <div className="popupTable__data">
              <Field
                disabled={!editable}
                name={`products.${index}.To Fit Chest`}
                type="text"
                value={product["To Fit Chest"]}
              />
            </div>
          )}
          {product["Inseam Length"] && (
            <div className="popupTable__data">
              <Field
                disabled={!editable}
                name={`products.${index}.Inseam Length`}
                type="text"
                value={product["Inseam Length"]}
              />
            </div>
          )}
          {product["Outseam Length"] && (
            <div className="popupTable__data">
              <Field
                disabled={!editable}
                name={`products.${index}.Outseam Length`}
                type="text"
                value={product["Outseam Length"]}
              />
            </div>
          )}
          <div
            className="popupTable__data"
            style={{
              position: "sticky",
              backgroundColor: "white",
              right: 0,
            }}
          >
            {editable ? (
              <div>
                <Button
                  title={"Save"}
                  onClick={() => {
                    setEditable(false);
                  }}
                />
              </div>
            ) : (
              <div className="df" style={{ minWidth: "100px" }}>
                <button
                  type="button"
                  onClick={() => setEditable(true)}
                  className="button--smallRound"
                >
                  <BsPencil className="mx-auto" />
                </button>
                <button
                  type="button"
                  onClick={() => remove(index)}
                  className="button--smallRound"
                >
                  <AiOutlineDelete className="mx-auto" />
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

const UploadedList = () => {
  const brand = [];
  const [filterBrand, setFilterBrand] = useState();
  const uploadedFile = localStorage?.getItem("uploadedFile");
  const fileName = localStorage?.getItem("fileName");
  const gender = localStorage?.getItem("gender");
  const category = localStorage?.getItem("category");
  const token = localStorage?.getItem("jwt_access_token");
  const myFile = JSON.parse(uploadedFile);
  const navigate = useNavigate();
  const location = useLocation();

  const uploadFileURL = "/products/uploadProductExcel";

  myFile?.map((item, i) => {
    brand.push(item.productBrand);
  });

  const initialValues = {
    products: myFile,
  };

  const submitHandler = async (values) => {
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(values?.products);
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "file");
    let wbout = XLSX.write(wb, { bookType: "xlsx", type: "array" });

    var blob = new Blob([new Uint8Array(wbout)], {
      type: "application/octet-stream",
    });

    // form data
    let formData = new FormData();
    formData.append("gender", gender);
    formData.append("category", category);
    formData.append("file", blob, fileName);

    const authHeader = () => {
      if (token) {
        return {
          Authorization: `Bearer ${token}`,
        };
      } else {
        return {};
      }
    };
    var requestOptions = {
      method: "POST",
      headers: authHeader(),
      body: formData,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BACKEND_BASE_URL_AFTER_LOGIN + uploadFileURL,
      requestOptions
    )
      .then((response) => response.text())
      .then((result) => {
        navigate("/NewListing");
        localStorage.setItem("isFileUploaded", true);
      })
      .catch((error) => console.log("error", error));
  };
  const doesArrayHasValue = initialValues.products.length > 0;
  return (
    <>
      <div className="bg-grey min-h-screen">
        <div className="col_11 mx-auto">
          <div className="row py-4 items-center">
            <div className="col">
              <h2 className="text-xl font-bold">Review List</h2>
            </div>
            <div>
              Sort By
              <select
                className="bg-white rounded-lg p-2 outline-none border-none ml-4 font-bold"
                onChange={(e) => {
                  const val = e.target.value;
                  setFilterBrand(val);
                }}
                defaultValue={"brand"}
              >
                <option defaultChecked value={"brand"} disabled>
                  Brand
                </option>
                <option defaultChecked value={""}>
                  Show All
                </option>
                {brand?.map((item, i) => {
                  return <option key={item}>{item}</option>;
                })}
              </select>
            </div>
          </div>
          {/* Table */}
          <div
            className="w-full text-left bg-white popupTable"
            style={{ overflowX: "scroll" }}
          >
            {/* <div className="row"> */}
            <div className="col_12 popupTable__head f_16_bold">
              <div style={{ display: "flex" }}>
                <div className="popupTable__data" style={{ minWidth: "200px" }}>
                  Product Name
                </div>
                <div className="popupTable__data">Image</div>
                <div className="popupTable__data">SKU</div>
                <div className="popupTable__data">Brand</div>
                <div className="popupTable__data">Category</div>
                <div className="popupTable__data">MRP</div>
                {doesArrayHasValue && initialValues.products[0]["Bust"] && (
                  <div className="popupTable__data">Bust</div>
                )}
                {doesArrayHasValue && initialValues.products[0]["Bust(in)"] && (
                  <div className="popupTable__data">Bust(in)</div>
                )}
                {doesArrayHasValue && initialValues.products[0]["Waist"] && (
                  <div className="popupTable__data">Waist</div>
                )}
                {doesArrayHasValue && initialValues.products[0]["Chest"] && (
                  <div className="popupTable__data">Chest</div>
                )}
                {doesArrayHasValue &&
                  initialValues.products[0]["Chest(in)"] && (
                    <div className="popupTable__data">Chest(in)</div>
                  )}
                {doesArrayHasValue && initialValues.products[0]["Hips"] && (
                  <div className="popupTable__data">Hips</div>
                )}
                {doesArrayHasValue &&
                  initialValues.products[0]["Front Length"] && (
                    <div className="popupTable__data">Front Length</div>
                  )}
                {doesArrayHasValue &&
                  initialValues.products[0]["Across Shoulder"] && (
                    <div className="popupTable__data">Across Shoulder</div>
                  )}
                {doesArrayHasValue &&
                  initialValues.products[0]["To Fit Bust"] && (
                    <div className="popupTable__data">To Fit Bust</div>
                  )}
                {doesArrayHasValue &&
                  initialValues.products[0]["To Fit Hips"] && (
                    <div className="popupTable__data">To Fit Hips</div>
                  )}
                {doesArrayHasValue &&
                  initialValues.products[0]["To Fit Waist"] && (
                    <div className="popupTable__data">To Fit Waist</div>
                  )}
                {doesArrayHasValue &&
                  initialValues.products[0]["To Fit Chest"] && (
                    <div className="popupTable__data">To Fit Chest</div>
                  )}
                {doesArrayHasValue &&
                  initialValues.products[0]["Inseam Length"] && (
                    <div className="popupTable__data">Inseam Length</div>
                  )}
                {doesArrayHasValue &&
                  initialValues.products[0]["Outseam Length"] && (
                    <div className="popupTable__data">Outseam Length</div>
                  )}
                <div
                  className="popupTable__data"
                  style={{
                    position: "sticky",
                    backgroundColor: "white",
                    right: 0,
                  }}
                >
                  Actions
                </div>
              </div>
            </div>
            <Formik
              initialValues={initialValues}
              onSubmit={async (values) => {
                await new Promise((r) => setTimeout(r, 500));
                submitHandler(values);
                // alert(JSON.stringify(values, null, 2));
              }}
            >
              {({ values }) => (
                <Form style={{ width: "fit-content" }}>
                  <FieldArray name="products">
                    {({ insert, remove, push }) => (
                      <div>
                        {values.products.length > 0 &&
                          values.products.map((friend, index) => {
                            let brandName =
                              values?.products[index]?.productBrand;
                            // console.log("Dont know anything",values.products[index].productBrand);
                            return (
                              <>
                                {filterBrand ? (
                                  brandName === filterBrand && (
                                    <List
                                      index={index}
                                      remove={remove}
                                      product={values?.products[index]}
                                    />
                                  )
                                ) : (
                                  <List
                                    index={index}
                                    remove={remove}
                                    product={values?.products[index]}
                                  />
                                )}
                              </>
                            );
                          })}
                      </div>
                    )}
                  </FieldArray>
                  <div
                    className="text-center bg-darkgrey popupTable__foot py-2"
                    style={{
                      position: "absolute",
                      width: `${(11 / 12) * 100}%`,
                    }}
                  >
                    <div className="popupTable__data">
                      <Button title="Submit" type="submit" />
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
            {/* </div> */}
          </div>
          {/* <table className="w-full text-left bg-white popupTable">
            <thead>
              <tr>
                <th>File Name</th>
                <th>Product</th>
                <th>Brand</th>
                <th>Category</th>
                <th>Price</th>
                <th>Size</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {myFile?.map((item, i) => {
                return (
                  <>
                    {filterBrand ? (
                      item?.productBrand === filterBrand && (
                        <tr className="border-b-2 border-darkgrey">
                          <td>{item.productName}</td>
                          <td>
                            <div className="h-16 w-16">
                              <img
                                src={item.productImageUrl}
                                className="w-full h-full"
                              />
                            </div>
                          </td>
                          <td>{item.productBrand}</td>
                          <td>{item.category}</td>
                          <td>Rs. {item.productPrice}</td>
                          <td>M, XL</td>
                          <td>
                            <div className="row justify-evenly">
                              <button onClick={() => alert("edit")}>
                                Edit
                              </button>
                              <button onClick={() => alert("del")}>Del</button>
                            </div>
                          </td>
                        </tr>
                      )
                    ) : (
                      <tr className="border-b-2 border-darkgrey">
                        <td>{item.productName}</td>
                        <td>
                          <div className="h-16 w-16">
                            <img
                              src={item.productImageUrl}
                              className="w-full h-full"
                            />
                          </div>
                        </td>
                        <td>{item.productBrand}</td>
                        <td>{item.category}</td>
                        <td>Rs. {item.productPrice}</td>
                        <td>M, XL</td>
                        <td>
                          <div className="row">
                            <button
                              onClick={() => alert("edit")}
                              className="button--smallRound"
                            >
                              <BsPencil className="mx-auto" />
                            </button>
                            <button
                              onClick={() => alert("del")}
                              className="button--smallRound"
                            >
                              <AiOutlineDelete className="mx-auto" />
                            </button>
                          </div>
                        </td>
                      </tr>
                    )}
                  </>
                );
              })}
            </tbody>
            <tfoot className="bg-darkgrey">
              <tr>
                <td colSpan={7} className="text-center w-full py-2">
                  <Button title={"Submit"} onClick={() => alert("submit")} />
                </td>
              </tr>
            </tfoot>
          </table> */}
        </div>
      </div>
    </>
  );
};

export default UploadedList;
