import React, { useEffect, useState } from "react";
import axios from "../../API/AXIOS/axios";

const PreviousOrdersTable = ({ dashboard }) => {
  // const navigate = useNavigate();
  // const location = useLocation();
  const [weekDaysItems, setWeekDaysItems] = useState();
  const [weekendsItems, setWeekendsItems] = useState();
  const [mainData, setMainData] = useState();
  const [totalAmount, setTotalAmount] = useState(0);

  // get data from backend
  const token = localStorage.getItem("jwt_access_token");
  const previousOrdersURL = "/order/previous-order";
  const authHeader = () => {
    if (token) {
      return {
        Authorization: `Bearer ${token}`,
      };
    } else {
      return {};
    }
  };
  const getData = () => {
    axios
      .get(previousOrdersURL, {
        headers: authHeader(),
      })
      .then((res) => {
        let resData = res.data;
        const weekItem = [];
        const weekendItem = [];
        resData.data.map(item => {
        weekItem.push(...item.weekDaysItems)
        weekendItem.push(...item.weekendsItems);
        })
        setMainData([...weekItem, ...weekendItem]);
        setWeekDaysItems(resData.weekDaysItems);
      }).then((res)=>{
        // getTotalAmount()
      }

      );
  };
const getTotalAmount = ()=>{
  let amount = 0;
  mainData?.map((items,i)=>{
    amount = Number(items?.mrp);
    setTotalAmount(totalAmount+amount)
  })
}
  useEffect(() => {
    getData();
  }, []);
   useEffect(() => {
   }, [mainData]);
  // useEffect(()=>{
  //   getTotalAmount()
  // },[mainData])
  // console.log("weekendsItems", weekendsItems);
  // console.log("weekDaysItems", weekDaysItems);
  return (
    <>
      <table className="w-full bg-white rounded-lg text-left overflow-hidden primarytable">
        <thead className="border-b-2 border-darkgrey">
          <tr>
            <th>Items</th>
            <th>Brands</th>
            <th>Category</th>
            <th>SKU</th>
            <th>MRP</th>
            <th>Pickup</th>
          </tr>
        </thead>
        <tbody className="">
          {mainData?.map((items, i) => {
            return (
              <tr
                key={i}
                className="border-b border-lightgrey text-subtext text-opacity-50"
              >
                <td>{items?.name}</td>
                <td>{items?.brand}</td>
                <td>{items?.category}</td>
                <td>{items?.sku}</td>
                <td>{items?.mrp}</td>
                <td>{items?.pickup_date}</td>
              </tr>
            );
          })}
          {/* {weekendsItems?.map((items, i) => {
            return (
              <tr
                key={i}
                className="border-b border-lightgrey text-subtext text-opacity-50"
              >
                <td>{items?.productName}</td>
                <td>{items?.productBrand}</td>
                <td>{items?.category}</td>
                <td>{items?.sku}</td>
                <td>{items?.productMRP}</td>
                <td>{items?.pickup_date}</td>
              </tr>
            );
          })} */}
        </tbody>
        {/* <tfoot className="bg-darkgrey">
          <tr>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>Rs. {totalAmount}</td>
            <td>
              <button
                className=""
                // onClick={() => navigate("/Uploaded" + location.search)}
              >
                <div className="row items-center">
                  Show All
                  <div className="rounded-full bg-primarylightest text-white w-7 h-7 mx-2">
                    {">"}
                  </div>
                </div>
              </button>
            </td>
          </tr>
        </tfoot> */}
      </table>
    </>
  );
};

export default PreviousOrdersTable;
