import { Field } from "formik";
import React from "react";

const InputField = ({
  varient,
  label,
  placeholder,
  error,
  name,
  onChange,
  required,
  touched,
  dirty,
  value,
}) => {
  return (
    <>
      <div className="inputField">
        <label className="inputField__label">{label}</label>
        <Field
          placeholder={placeholder}
          name={name}
          type={varient}
          className="inputField__field"
          required={required}
          touched={touched}
          dirty={dirty}
          value={value}
        />
        <p className="inputField__error">{error}</p>
      </div>
    </>
  );
};

export default InputField;
