import React, { useState } from "react";
import Button from "../../CommonComponents/Buttons";
import QualityCheckList from "./QualityCheckList";

const QualityCheck = ()=>{
    const [showList, setShowList] = useState(true);
    return (
      <>
        <div className="text-center pb-5">
          <h2 className="text-textlight font-bold text-lg">
            Good!! Your file has been submitted for QC
          </h2>
        </div>
        <div className="py-2">
          {/* <div className="py-3 text-center bg-darkgrey rounded-tl-lg rounded-tr-lg">
            <Button
              title={"View List"}
              type={"button"}
              onClick={() => setShowList(!showList)}
            />
          </div> */}
          {showList && <QualityCheckList />}
        </div>
      </>
    );
}

export default QualityCheck;