import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../API/AXIOS/axios";
import Button from "../CommonComponents/Buttons";
import Cards from "./Cards";
import PaymentTable from "./PaymentTable";
import UploadedTable from "./UploadedTable";

const HomePage = () => {
  const [dashboardData, setDashboardData] = useState();
  const [excelCount, setExcelCount] = useState();
  const dashboardURL = "/get-dashboard-detail";
  const getUploadedProductExcelCount = "/product/getUploadedProductExcelCount";
  const newOrdersURL = "/order/new-order";
  const authHeader = () => {
    const token = localStorage.getItem("jwt_access_token");
    if (token) {
      return {
        Authorization: `Bearer ${token}`,
      };
    } else {
      window.location.reload();
    }
  };
  const getDashboardData = () => {
    axios
      .get(dashboardURL, {
        headers: authHeader(),
      })
      .then((res) => {
        const data = res.data.data;
        setDashboardData(data);
      })
      .catch((err) => {
        localStorage.clear();
        window.location.reload();
      });
  };
  const getUploadedProductExcelCountApi = () => {
    axios
      .get(getUploadedProductExcelCount, {
        headers: authHeader(),
      })
      .then((res) => {
        const data = res.data.data;
        setExcelCount(data);
      })
      .catch((err) => {
        localStorage.clear();
        window.location.reload();
      });
  };

  useEffect(() => {
    getDashboardData();
    getUploadedProductExcelCountApi();
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const userArray = [
    {
      name: "deepak",
      age: 20,
    },
    {
      name: "test",
      age: 30,
    },
  ];
  return (
    <>
      <div className="mainContainer bg-grey">
        <div className="customContainer pt-7">
          <section className="py-2 text-right">
            <Button
              title={"+ New Listing"}
              onClick={() => {
                navigate("/NewListing" + location.search);
              }}
            />
          </section>

          <section>
            {/* <h3 className="font-bold pb-4 pt-1 text-xl">Orders</h3> */}
            {/* cards start */}
            <Cards
              newOrders={dashboardData?.newOrder}
              noOfItems={dashboardData?.newProduct}
              pickupSchedule={dashboardData?.scheduled}
              lastWeek={dashboardData?.lastWeekProduct}
              lastMonth={dashboardData?.lastMonthProduct}
              excelCount={excelCount}
            />
            {/* cards end */}
          </section>
          <section className="py-4">
            <h3 className="font-bold pb-4 pt-1 text-xl">Uploaded</h3>
            {/* Uploaded Table Start */}
            <UploadedTable dashboard />
            {/* Uploaded Table End */}
          </section>
          <section className="py-4">
            <h3 className="font-bold pb-4 pt-1 text-xl">Payments</h3>
            {/* Payment Table Start */}
            <PaymentTable />
            {/* Payment Table End */}
          </section>
        </div>
      </div>
    </>
  );
};

export default HomePage;
