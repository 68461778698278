import React from "react";
import Header from "../CommonComponents/Header";
import PreviousOrdersTable from "./PreviousOrdersTable";
import QualityCheckList from "../NewListing/QualityCheck/QualityCheckList";

const QCList = () => {
  return (
    <>
      <div className="bg-grey min-h-screen">
        {/* <Header /> */}
        <div className="col_11 mx-auto">
          <QualityCheckList />
        </div>
      </div>
    </>
  );
};

export default QCList;
