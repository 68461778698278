import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import axios from "../../API/AXIOS/axios";
import UploadedListPopup from "../Popups/UploadedListPopup";

const UploadedTable = ({ dashboard }) => {
  const [showPopup, setShowPopup] = useState(false);
  const [mainData, setMainData] = useState([]);

  const navigate = useNavigate();
  const location = useLocation();

  const token = localStorage.getItem("jwt_access_token");
  const uploadedFilesUrl = "/product/getUploadedProductExcel";
  const authHeader = () => {
    if (token) {
      return {
        Authorization: `Bearer ${token}`,
      };
    } else {
      return {};
    }
  };
  const getData = () => {
    axios
      .get(uploadedFilesUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        let resData = res.data.data;
        setMainData(resData);
      });
  };
  useEffect(() => {
    getData();
  }, []);
  return (
    <>
      <table className="w-full bg-white rounded-lg text-left overflow-hidden primarytable">
        <thead className="border-b-2 border-darkgrey">
          <th>Files</th>
          <th>Uploaded</th>
          <th>Listed</th>
          <th>No. of Orders</th>
          <th>Date of Upload</th>
        </thead>
        <tbody className="">
          {mainData?.map((items, i) => {
            let createdDate = items.createdAt + "";
            let result = createdDate.slice(0, 10);
            return dashboard ? (
              i < 4 && (
                <tr className="border-b border-lightgrey">
                  <td>{items.name}</td>
                  <td>
                    <span className="text-subtext">{items.count}</span>
                  </td>
                  <td>{items.selection}</td>
                  <td>{items.orders}</td>
                  <td>{items.createdAt.slice(0, 10)}</td>
                </tr>
              )
            ) : (
              <tr className="border-b border-lightgrey">
                <td>{items.name}</td>
                <td>
                  <span className="text-subtext">{items.count}</span>
                </td>
                <td>{items.selection}</td>
                <td>{items.orders}</td>
                <td>{items.createdAt.slice(0, 10)}</td>
              </tr>
            );
          })}
          {/* <tr className="border-b border-lightgrey">
              <td>A</td>
              <td>
                <span className="text-subtext">23</span>
              </td>
              <td>14</td>
              <td>23</td>
              <td>30 Jun’ 2022</td>
            </tr> */}
        </tbody>
        <tfoot className="bg-darkgrey">
          <td></td>
          <td></td>
          <td></td>
          <td></td>
          <td>
            {dashboard && (
              <button
                className=""
                onClick={() => {
                  navigate("/Uploaded" + location.search);
                  // console.log("ppppppp", location.search);
                }}
              >
                <div
                  className="row items-center"
                >
                  Show All
                  <div className="rounded-full bg-primarylightest text-white w-7 h-7 mx-2">
                    {">"}
                  </div>
                </div>
              </button>
            )}
          </td>
        </tfoot>
      </table>
      {showPopup && <UploadedListPopup closePopup={setShowPopup} />}
    </>
  );
};

export default UploadedTable;
