import React from "react";
import QCList from "../components/QCList";
const QCListPage=() =>{
    return (
      <>
        <QCList/>
      </>
    );
}

export default QCListPage;