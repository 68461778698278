import React from "react";
import Login from "../components/Login";

const LoginPage = ()=>{
    localStorage.clear();
    return(
        <>
        <Login />
        </>
    )
}

export default LoginPage;