import React from "react";
import PreviousOrders from "../components/PreviousOrders";

const PreviousOrdersPage=() =>{
    return (
      <>
        <PreviousOrders />
      </>
    );
}

export default PreviousOrdersPage;