import React, { useEffect, useState } from "react";
import axios, { IMAGE_URL } from "../../../API/AXIOS/axios";
import Button from "../../CommonComponents/Buttons";
import moment, * as moments from "moment";
import MappleToolTip from "reactjs-mappletooltip";
import "./index.css";
import QCPopup from "../../Popups/QCPopup";
const QualityCheckList = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState([]);
  const [title, setTitle] = useState("");
  const [link, setLink] = useState("");
  const [data, setData] = useState();
  const [isActive, setIsActive] = useState(0);
  // get data from backend
  const token = localStorage.getItem("jwt_access_token");
  const uploadedFilesURL = "product/getUploadedProductExcel";
  const authHeader = () => {
    if (token) {
      // console.log("token", token);
      return {
        Authorization: `Bearer ${token}`,
      };
    } else {
      return {};
    }
  };
  useEffect(() => {
    axios
      .get(
        uploadedFilesURL +
          `?status=${
            isActive == 0 ? "Approved" : isActive == 1 ? "Rejected" : "Pending"
          }`,
        {
          headers: authHeader(),
        }
      )
      .then((res) => {
        let data = res.data.data;
        setData(data);
      });
  }, [isActive]);
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "start",
          borderTopLeftRadius: "16px",
          boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <div
          className="bg-primary"
          style={{
            color: isActive == 0 ? "white" : "#6C62DB",
            padding: "5px 20px 5px 20px",
            backgroundColor: isActive == 0 ? undefined : "white",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            borderTopLeftRadius: "10px",
            borderWidth: "1px",
            borderColor: "#6C62DB",
            borderpStyle: "solid",
            cursor: "pointer",
            // borderTopColor:'#6C62DB',
          }}
          onClick={() => {
            setIsActive(0);
          }}
        >
          Approved
        </div>
        <div
          className="bg-primary"
          style={{
            color: isActive == 1 ? "white" : "#6C62DB",
            padding: "5px 20px 5px 20px",
            backgroundColor: isActive == 1 ? undefined : "white",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            borderWidth: "1px",
            borderColor: "#6C62DB",
            borderStyle: "solid",
            borderLeftWidth: 0,
            borderRightWidth: 0,
            cursor: "pointer",
          }}
          onClick={() => {
            setIsActive(1);
          }}
        >
          Rejected
        </div>
        <div
          className="bg-primary"
          style={{
            color: isActive == 2 ? "white" : "#6C62DB",
            padding: "5px 20px 5px 20px",
            backgroundColor: isActive == 2 ? undefined : "white",
            boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
            borderTopRightRadius: "10px",
            borderWidth: "1px",
            borderColor: "#6C62DB",
            borderStyle: "solid",
            cursor: "pointer",
          }}
          onClick={() => {
            setIsActive(2);
          }}
        >
          Pending
        </div>
      </div>
      <table className="qualifyCheckListTable">
        <thead className="text-left">
          <tr>
            <th className="">File Name</th>
            <th>Image</th>
            <th>Date</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {data?.map((item, i) => {
            return (
              <tr>
                <td>{item.name}</td>
                <td>
                  <div className="h-20 w-20">
                    <img
                      src={
                        item?.gender == "male"
                          ? "/assets/images/men.png"
                          : "/assets/images/girl.png"
                      }
                      className="w-4/7 h-full"
                    />
                  </div>
                </td>
                <td>
                  {item?.createdAt &&
                    moment(item?.createdAt).format("DD MMM' YYYY")}
                </td>
                {item.status === "Rejected" ? (
                  <td>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span className="text-red">Rejected</span>
                      <div>
                        <Button
                          title={"See what to do?"}
                          type={"button"}
                          onClick={() => {
                            setShowPopup(!showPopup);
                            setPopupData(item?.errorMessageArr);
                            setLink(item?.messageUrl);
                            setTitle("Error List");
                          }}
                          varient={"outlined"}
                          // tooltip={item?.message}
                          custom={"text-subtext font-medium border-red mx-2"}
                        />
                      </div>
                    </div>
                  </td>
                ) : item.status === "Approved" ? (
                  <td>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <span className="text-success">Approved</span>
                      <div>
                        <Button
                          title={"Know more"}
                          type={"button"}
                          onClick={() => {
                            setShowPopup(!showPopup);
                            setPopupData(item?.duplicateArr);
                            setLink(item?.messageUrl);
                            setTitle("Duplicate List");
                          }}
                          varient={"outlined"}
                          // tooltip={item?.message}
                          custom={
                            "text-subtext font-medium border-green-400 mx-2 text-success"
                          }
                        />
                      </div>
                    </div>
                  </td>
                ) : (
                  <td
                    className={[
                      item.status == "Pending" && "text-primary",
                      item.status == "Approved" && "text-success",
                      item.status == "text-red",
                      "font-normal",
                    ].join(" ")}
                  >
                    {item.status}
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      {showPopup ? (
        <QCPopup
          closePopup={() => {
            setShowPopup(!showPopup);
          }}
          popupData={popupData}
          title={title}
          link={link}
        />
      ) : null}
    </>
  );
};

export default QualityCheckList;
