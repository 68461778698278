import React, { useEffect } from "react";

const Popup =({closePopup, title, ...props})=>{
    document.addEventListener("mousedown", (e) => {
              const concernedElement = document.querySelector(".popupouter");
            // console.log("concernedElement", concernedElement,"current click", e.target);
            e.target === concernedElement && closePopup(false)
          });
    return (
      <>
        <div className="popupouter" style={{cursor:"default"}}>
          <div className="popupContainer" id="popupOuterBox">
            <button
              className="popupCross z-20"
              onClick={() => closePopup(false)}
            >
              <img
                src="/assets/images/cross.svg"
                onClick={() => closePopup(false)}
              />
            </button>
            <div className="popupHeader">
              <h2>{title}</h2>
            </div>
            {props.children}
          </div>
        </div>
      </>
    );
}

export default Popup;