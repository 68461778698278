import axios from "axios";

export const IMAGE_URL = "https://api.snuggle-lifestyle.com/api/v1/retailer/";
export default axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL_AFTER_LOGIN,
  timeout: 1000000,
  headers: {
    "Content-Type": "application/json",
    withCredentials: false,
  },
});

export const loginPortal = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
  timeout: 1000000,
  headers: {
    "Content-Type": "application/json",
  },
});
// let token = "";

// export const setToken = (_token) => {
//   token = "bearer " + _token;
// };

// export const axiosPortal = axios.create({
//   baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
//   timeout: 1000000,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// export const portalTokenAxios = axios.create({
//   baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
//   timeout: 1000000,
//   headers: {
//     "Content-Type": "application/json",
//   },
// });

// export const imageUploadPortal = axios.create({
//   baseURL: process.env.REACT_APP_BACKEND_BASE_URL,
//   timeout: 1000000,
//   headers: {
//     "Content-Type": "multipart/form-data",
//   },
// });

// portalTokenAxios.interceptors.request.use(
//   (config) => {
//     const authToken = `${token}`;
//     if (config.method !== "options") {
//       config.headers = {
//         ...config.headers,
//         "x-api-key": process.env.REACT_APP_SECRETKEY,
//         Authorization: authToken,
//       };
//     }
//     return config;
//   },
//   (error) => {
//     // Do something with the error
//     Promise.reject(error);
//   }
// );

// imageUploadPortal.interceptors.request.use(
//   (config) => {
//     const authToken = `${token}`;

//     if (config.method !== "options") {
//       config.headers = {
//         ...config.headers,
//         "x-api-key": process.env.REACT_APP_SECRETKEY,
//         Authorization: authToken,
//       };
//     }
//     return config;
//   },
//   (error) => {
//     // Do something with the error
//     Promise.reject(error);
//   }
// );
