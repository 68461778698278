import React from "react";
import Payment from "../components/Payment";

const PaymentPage =()=>{
    return(
        <>
        <Payment />
        </>
    )
}
export default PaymentPage