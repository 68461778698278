import React, { useEffect, useState } from "react";
import LoginForm from "./LoginForm";
// import { sideLogo } from "../../assets/images/retailor-bg.svg";

const Login =()=>{
const [FormHeight, setFormHeight] = useState("");
const [BannerHeight, setBannerHeight] = useState("");
useEffect(() => {
  let formHeight  = document.getElementById("form__container").clientHeight / 2 + "px";
  // let bannerHeight = document.getElementById("banner__container").clientHeight / 2 + "px";
  setFormHeight(formHeight);
  // setBannerHeight(bannerHeight);
}, [])

const bannerHeight = document.getElementById("banner__container");
const resize = ()=>{
  let banner = bannerHeight.clientHeight /2 + "px";
  setBannerHeight(banner);
}
window.onload= function(){resize()}

  return (
    <>
      <div className="row">
        <div className="row">
          <div className="col_6 text-center min-h-screen">
            <div
              className="relative"
              style={{ top: `calC(50% - ${BannerHeight})` }}
              id="banner__container"
            >
              <h2 className="font-bold text-base">
                Manage the ecosystem <br /> Generate Orders
              </h2>
              <p>
                {"Track & review the performance of team and"}
                <br /> the theme
              </p>
            <div className="py-20">
              <img
                src="/assets/images/retailor-bg.svg"
                alt="userProfile"
                className="mx-auto"
              />
            </div>
            </div>
          </div>
          <div className="col_6 min-h-screen bg-grey">
            <div
              className="relative"
              style={{ top: `calC(50% - ${FormHeight})` }}
              id="form__container"
            >
              <div className="text-center text-2xl font-bold py-8">LOGIN</div>
              <div className="col_6 mx-auto">
                <LoginForm />
                <div className="text-center">
                  <button className="text-primary">Forgot Password?</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;