import React, { useEffect, useRef, useState } from "react";
import Header from "../CommonComponents/Header";
import DropDown from "../CommonComponents/InputField/DropDown";
import Stepper from "./Stepper";
import Button from "../CommonComponents/Buttons";
import ListLoader from "./ListLoader";
import QualityCheck from "./QualityCheck";
import axios from "../../API/AXIOS/axios";
import * as XLSX from "xlsx";
import UploadFile from "./UploadFile.js";
import { useLocation } from "react-router-dom";

const NewListing = () => {
  const [activeTab, setActiveTab] = useState(1);
  const [excellFile, setExcelFile] = useState();
  const [gender, setGender] = useState("male");
  const [category, setCategory] = useState();
  const [catVal, setCatVal] = useState(null);
  const categories = [];
  const { state } = useLocation();

  const token = localStorage.getItem("jwt_access_token");
  // excellFile URL
  let excellFileDownloadLink = `/config/${gender}/${catVal}/getSampleFile`;
  // Categories URL
  let categoryUrl = `/category/${gender}/getAllMain`;

  // comomn authHead
  const authHeader = () => {
    if (token) {
      // console.log("token", token);
      return {
        Authorization: `Bearer ${token}`,
      };
    } else {
      return {};
    }
  };
  const inputFileRef = useRef(null);

  // download file button handler
  const downloadFileHandler = () => {
    axios
      .get(excellFileDownloadLink, {
        headers: authHeader(),
      })
      .then((res) => {
        const link = res.data.data;
        // window.location.href = link;
        window.open(link);
      })
      .then(() => {
        setActiveTab(3);
      });
  };

  // getExcell File

  const getExcelSheet = () => {
    axios
      .get(excellFileDownloadLink, {
        headers: authHeader(),
      })
      .then((res) => {
        const file = res.data.data[0].url;
        setExcelFile(file);
        setActiveTab(2);
      });
  };

  // to get category onClick
  const getCategory = async () => {
    await axios
      .get(categoryUrl, {
        headers: authHeader(),
      })
      .then((res) => {
        // console.log("categoryData =====>", res.data.data);
        let data = res.data.data;
        data?.map((item, i) => {
          // console.log("item of data", item.catType);
          categories.push(item.catType);
        });
        // console.log("catagories =========>", categories);
        setCategory(categories);
      });
  };

  // to get catgory onLoad
  useEffect(async () => {
    getCategory();
  }, [gender]);
  useEffect(async () => {
    localStorage.setItem("category", catVal);
  }, [catVal]);

  useEffect(() => {
    const isFileUploaded = localStorage.getItem("isFileUploaded");
    if (isFileUploaded == "true") {
      setActiveTab(4);
      localStorage.setItem("isFileUploaded", false);
    }
  }, []);

  const onTabClick = (tab) => {
    setActiveTab(tab);
  };

  return (
    <>
      {/* <Header /> */}
      <div className="newListing">
        <div className="customContainer pb-4">
          <Stepper
            isActive={activeTab}
            category={catVal}
            setIsActive={(tab) => onTabClick(tab)}
          />
          <div>
            {/* drop down */}
            <section className="col_7 mx-auto">
              <div className="text-center py-3">
                <label className="mx-3">Gender</label>
                <label className="mx-3" style={{ color: "#707070" }}>
                  <input
                    type={"radio"}
                    // disabled={activeTab !== 1}
                    className="input__radio"
                    onClick={() => {
                      setGender("male");
                      setCatVal("");
                      // getCategory();
                    }}
                    name="gender"
                    checked={gender === "male"}
                  />
                  Men
                </label>
                <label className="mx-3" style={{ color: "#707070" }}>
                  <input
                    type={"radio"}
                    // disabled={activeTab !== 1}
                    className="input__radio"
                    onClick={() => {
                      setGender("female");
                      setCatVal("");
                      // getCategory();
                    }}
                    name="gender"
                    checked={gender === "female"}
                  />
                  Women
                </label>
              </div>
              <DropDown
                // disabled={activeTab !== 1}
                gender={gender}
                setGender={setGender}
                category={category}
                setCategory={setCatVal}
                catVal={catVal}
              />
              <div className="py-3">
                <Button
                  type={"button"}
                  title={"Next"}
                  onClick={getExcelSheet}
                  disable={activeTab !== 1 || !catVal}
                />
              </div>
              <div className="py-3">
                <Button
                  type={"button"}
                  title={"Download Template"}
                  custom={"w-full"}
                  onClick={downloadFileHandler}
                  disable={activeTab !== 2}
                />
                <a></a>
              </div>
              <div className="py-3">
                <UploadFile disable={activeTab !== 3 || !catVal} />
              </div>
            </section>
          </div>
        </div>
        {/* Quality Check */}
        <section className="py-7">
          {activeTab === 4 ? (
            false ? (
              <ListLoader />
            ) : (
              <div className="col_11 mx-auto">
                <QualityCheck />
              </div>
            )
          ) : (
            <div className="text-center text-textlight font-bold text-lg text-opacity-40">
              Status of Quality Check for the file
            </div>
          )}
        </section>
      </div>
    </>
  );
};

export default NewListing;
