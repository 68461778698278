import { React, useEffect, useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";

import LoginPage from "./Login";
import Header from "../components/CommonComponents/Header";
import AuthRoutes from "../Routes-Components/Auth-Routes/AuthRoutes";
import { PrivateRoutes } from "../RoutesDeclaration";

const RootRouter = () => {
  const [isLogin, setIsLogin] = useState(false);
  const jwt_access_token = localStorage.getItem("jwt_access_token");
  // Adding Redux state of isLogin to make those app redux ready
  const isUserLogin = jwt_access_token ? true : false;
  useEffect(()=>{
    setIsLogin(isUserLogin);
  },[])
  return (
    <Router>
      {isLogin ? <Header /> : null}
      <Routes>
        <Route element={<AuthRoutes />}>
          {PrivateRoutes.map((items, i) => {
            return <Route key={i} exact path={items.path} element={items.component} />;
          })}
        </Route>
        <Route exact path="/Login" element={<LoginPage />} />
      </Routes>
    </Router>
  );
};

export default RootRouter;
