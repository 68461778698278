import React from "react";
import Header from "../CommonComponents/Header";
import UploadedTable from "../LandingPage/UploadedTable";

const UploadedPage = ()=>{
    return(
        <>
        <div className="bg-grey min-h-screen">
        {/* <Header /> */}
        <div className="col_11 mx-auto">
            <div className="row py-4 items-center">
                <div className="col">
                    <h2 className="text-xl font-bold">Uploaded</h2>
                </div>
                <div>
                    Sort By 
                    <select className="bg-white rounded-lg p-2 outline-none border-none ml-4 font-bold">
                        <option>Brand</option>
                    </select>
                </div>
            </div>
            <UploadedTable />
        </div>
        </div>
        </>
    )
}

export default UploadedPage;