import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Button from "../CommonComponents/Buttons";
import InputField from "../CommonComponents/InputField";
import * as yup from "yup";
import { loginPortal } from "../../API/AXIOS/axios.js";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const LoginForm = () => {
  const loginURL = "/loginAddedUsers";
  let navigate = useNavigate();
  let location = useLocation();
  const [loginData, setLoginData] = useState();
  const [message, setMessage] = useState();
  const [errorMessage, setErrorMessage] = useState();

  const validationSchema = yup.object({
    username: yup
      .string()
      .email("Enter a Valid username")
      .required("Email is required"),
    password: yup.string().max(30).required("Password is required"),
  });
  const initialValues = {
    username: "",
    password: "",
    role: "retailer",
  };

  const loginFormAuth = async (e) => {
    try {
      const res = await loginPortal
        .post(loginURL, JSON.stringify(e), {
          withCredentials: false,
        })
        .then((res) => {
          console.log("for the user", res.data.data);
          const accessToken = res.data.data.token;
          let userData = JSON.stringify(res.data.data);
          localStorage.setItem("userData", userData);
          localStorage["name"] = res.data.data.firstName;
          localStorage.setItem("jwt_access_token", accessToken);
          res.data.message === "Success."
            ? navigate("/" + location.search)
            : navigate("/Login" + location.search);
          window.location.reload();
        })
        .catch((err) => {
          console.log("errerr 2", err, err?.data);
          console.log("errerr 2", err?.response?.data);
          toast(err?.response?.data?.message || "CORS error", {
            type: "error",
          });
        });
    } catch (err) {
      console.log("ererrr", err);
      console.log("ererrr", err?.res);

      if (!err?.res) {
        setErrorMessage("No Server Response");
      } else if (err.res?.status === 400) {
        setErrorMessage("Missing Username or Password");
      } else if (err.res?.status === 401) {
        setErrorMessage("Unauthorized");
      } else {
        setErrorMessage("Login Failed");
      }
    }
  };
  return (
    <>
      <Formik
        initialValues={initialValues}
        validateOnBlur
        validationSchema={validationSchema}
        onSubmit={(e) => {
          loginFormAuth(e);
        }}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          dirty,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <div>
              <InputField
                name={"username"}
                placeholder={"Enter your Email Address"}
                label={"mail Address"}
                varient={"email"}
                error={errors.email}
                required
                // touched
                // dirty
              />
            </div>
            <InputField
              name={"password"}
              placeholder={"Enter your Password"}
              label={"Password"}
              varient={"password"}
              error={errors.password}
              required
              // touched
              // dirty
            />
            <div className="py-6">
              <Button title="Submit" type="submit" custom={"w-full"} />
            </div>
          </Form>
        )}
      </Formik>
      <ToastContainer />
    </>
  );
};

export default LoginForm;
