import React, { useEffect, useState } from "react";
import Popup from ".";
import axios from "../../API/AXIOS/axios";
import Button from "../CommonComponents/Buttons";
import "./style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const QCPopup = ({ closePopup, popupData, title, link }) => {
  const [data, setData] = useState(popupData);
  console.log("popupData", popupData);
  return (
    <>
      <Popup title={title} closePopup={closePopup}>
        <div
          className="px-4"
          style={{
            overflow: "scroll",
            maxHeight: "400px",
            overflowX: "hidden",
          }}
        >
          <div>
            {/* Head Part */}
            <table style={{ width: "100%" }}>
              <tr
                style={{
                  height: "60px",
                  borderBottom: "0.5px solid rgb(227 227 227)",
                }}
              >
                {title == "Error List" ? (
                  <th
                    style={{
                      textAlign: "left",
                      width: "45%",
                    }}
                  >
                    Error Type
                  </th>
                ) : null}

                <th style={{ textAlign: "left", width: "25%" }}>
                  {title == "Error List" ? "Error Position" : "Sku Code"}
                </th>
                <th
                  style={{
                    textAlign: "left",
                    width: title == "Error List" ? "30%" : "45%",
                  }}
                >
                  {title == "Error List" ? "Error Message" : "Product Details"}
                </th>
              </tr>
              {data?.map((item, i) => (
                <tr
                  style={{
                    height: "60px",
                    background: "#F8F8F8",
                    borderBottom: "0.5px solid rgb(227 227 227)",
                  }}
                  key={i}
                >
                  <td style={{ textAlign: "left" }}>{item[0]}</td>
                  <td style={{ textAlign: "left" }}>{item[1]}</td>
                  {item[2] ? (
                    <td style={{ textAlign: "left" }}>{item[2]}</td>
                  ) : null}
                </tr>
              ))}
            </table>
          </div>
        </div>
        {link ? (
          <div style={{ textAlign: "center", margin: "12px" }}>
            <Button
              title={"Download"}
              onClick={() => {
                window.open(link, "_blank");
                // navigate("/NewListing" + location.search);
              }}
            />
          </div>
        ) : null}
      </Popup>
    </>
  );
};

export default QCPopup;

{
  /* <NewOrder
                        name={item.productName}
                        sku={item.sku}
                        product_id={item.productId}
                        order_id={item._id}
                        day={item.day}
                      /> */
}

{
  /* <div className="row bg-primarylight bg-opacity-10 px-2 max-h-44 overflow-auto">
              {newOrdersData.map((items, i) => {
                return items.status != "Requested" ? (
                  !showList ? (
                    items.status !== "Rejected" && (
                      <div key={i} className="w-full">
                        <div className="row py-1">
                          <div className="col capitalize">{items.name}</div>
                          <div
                            className={[
                              "col text-center capitalize",
                              items.status === "Approved" && "text-success",
                              items.status === "Rejected" && "text-darkred",
                              items.status === "Pending" && "text-textDisabled",
                            ].join(" ")}
                          >
                            {items.status}
                          </div>
                        </div>
                      </div>
                    )
                  ) : (
                    <>
                      <div className="row py-1">
                        <div className="col capitalize">{items.name}</div>
                        <div
                          className={[
                            "col text-center capitalize",
                            items.status === "Approved" && "text-success",
                            items.status === "Rejected" && "text-darkred",
                            items.status === "Pending" && "text-textDisabled",
                          ].join(" ")}
                        >
                          {items.status}
                        </div>
                      </div>
                    </>
                  )
                ) : null;
              })}
            </div> */
}
