import React from "react";
import { Navigate, Outlet } from "react-router-dom";

const AuthRoutes = ({ children, ...rest }) => {
  const jwt_access_token = localStorage.getItem("jwt_access_token");
  // Adding Redux state of isLogin to make those app redux ready
  const isLogin = jwt_access_token ? true : false;
  return <>{isLogin ? <Outlet /> : <Navigate to={"/Login"} />}</>;
};

export default AuthRoutes;
