import React from "react";

const StepperButton = ({
    stepNumber,
    text,
    isActive,
    setIsActive,
    onClick,
})=>{
    return (
      <>
        <button
          className={["stepper__button", isActive === stepNumber && "stepper__button--active"].join(" ")}
          onClick={()=>{
            if(onClick){
              onClick()
            }
            else{

              setIsActive(stepNumber)
            }
        }}
        >
          <div className="stepper__count">{stepNumber}</div>
          <p className="stepper__text">{text}</p>
        </button>
      </>
    );
}

const Stepper =({
    isActive,
    setIsActive,  
    category
})=>{
    return (
      <>
        <div className="row">
          <div className="col text-center">
            <StepperButton
              stepNumber={1}
              text={"Choose Category"}
              isActive={isActive}
              setIsActive={setIsActive}
              />
          </div>
          <div className="col text-center">
            <StepperButton
              stepNumber={2}
              text={"Download Template"}
              isActive={isActive}
              setIsActive={setIsActive}
              onClick={()=>alert("Please Select category first")}
              />
          </div>
          <div className="col text-center">
            <StepperButton
              stepNumber={3}
              text={"Upload Sheet"}
              isActive={isActive}
              setIsActive={setIsActive}
              // onClick={()=> category == null ?  alert("Please Select category first"): setIsActive(3)}
              />
          </div>
          <div className="col text-center">
            <StepperButton
              stepNumber={4}
              text={"Quality Check"}
              isActive={isActive}
              setIsActive={setIsActive}
            />
          </div>
        </div>
      </>
    );
}

export default Stepper;