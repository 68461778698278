import React, { useEffect, useState } from "react";
import Popup from ".";
import axios from "../../API/AXIOS/axios";
import Button from "../CommonComponents/Buttons";
import "./style.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const NewOrdersPopup = ({ closePopup }) => {
  const [showList, setShowList] = useState(false);
  const [newOrdersData, setNewOrdersData] = useState([]);
  const [orderId, setOrderId] = useState("");

  const newOrdersURL = "/order/new-order";
  const authHeader = () => {
    const token = localStorage.getItem("jwt_access_token");
    if (token) {
      return {
        Authorization: `Bearer ${token}`,
      };
    } else {
      return {};
    }
  };

  const getNewOrdersData = () => {
    axios
      .get(newOrdersURL, {
        headers: authHeader(),
      })
      .then((res) => {
        const data = res.data.data;
        const newOrderData = [];
        data.map((item) => {
          const weekdays = [];
          if (item?.weekendsItems?.length > 0) {
            item?.weekDaysItems?.map((week) => {
              week.order_id = item?._id;
              weekdays.push(week);
            });
          }
          const weekends = [];
          if (item?.weekendsItems?.length > 0) {
            item?.weekendsItems?.map((week) => {
              week.order_id = item?._id;
              weekends.push(week);
            });
          }
          newOrderData.push(...weekdays, ...weekends);
        });
        setNewOrdersData(newOrderData);
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getNewOrdersData();
  }, []);

  const updateOrder = (val, product_id, id, day, order_id) => {
    const UpdateOrderURL = "order/update-product-status";
    let body = {
      product_id: product_id,
      order_id: order_id,
      status: val ? "Approved" : "Rejected",
      type: !["Sunday", "Saturday"].includes(day)
        ? "weekendsItems"
        : "weekDaysItems",
    };
    const headers = authHeader();
    axios
      .post(UpdateOrderURL, body, { headers })
      .then((res) => {
        if (res.status) {
          toast("Order status updated successfully.", { type: "success" });
          getNewOrdersData();
        }
      })
      .catch((err) => {
        toast("Could not updated order status, Please try again.", {
          type: "success",
        });
      });
  };

  return (
    <>
      <Popup title="New Orders" closePopup={closePopup}>
        <div
          className="px-4"
          style={{
            overflow: "scroll",
            maxHeight: "400px",
            overflowX: "hidden",
          }}
        >
          <div>
            {/* Head Part */}
            <table style={{ width: "100%" }}>
              <tr
                style={{
                  height: "60px",
                  borderBottom: "0.5px solid rgb(227 227 227)",
                }}
              >
                <th style={{ textAlign: "center", width: "20%" }}>Items</th>
                <th style={{ textAlign: "center", width: "30%" }}>SKU</th>
                <th>Status</th>
              </tr>
              {newOrdersData?.map((item, i) =>
                item.status != "Approved" && item.status != "Rejected" ? (
                  <tr
                    style={{
                      height: "60px",
                      borderBottom: "0.5px solid rgb(227 227 227)",
                    }}
                    key={i}
                  >
                    <td style={{ textAlign: "center" }}>{item.name}</td>
                    <td style={{ textAlign: "center" }}>{item.sku}</td>
                    <td
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "60px",
                      }}
                    >
                      <div className="px-2">
                        <Button
                          type={"button"}
                          title={"Approve"}
                          custom={"button--success"}
                          padding="3px"
                          onClick={() =>
                            updateOrder(
                              true,
                              item.productId,
                              item._id,
                              item.day,
                              item?.order_id
                            )
                          }
                        />
                      </div>
                      <div className="px-2">
                        <Button
                          type={"button"}
                          title={"Reject"}
                          custom={"button--danger"}
                          padding="3px"
                          onClick={() =>
                            updateOrder(
                              false,
                              item.productId,
                              item._id,
                              item.day,
                              item?.order_id
                            )
                          }
                        />
                      </div>
                    </td>
                  </tr>
                ) : (
                  <tr
                    style={{
                      height: "60px",
                      background: "#F8F8F8",
                      borderBottom: "0.5px solid rgb(227 227 227)",
                    }}
                    key={i}
                  >
                    <td style={{ textAlign: "center" }}>{item.name}</td>
                    <td style={{ textAlign: "center" }}>{item.sku}</td>
                    <td>
                      <div
                        className={[
                          "col text-center capitalize",
                          item.status === "Approved" && "text-success",
                          item.status === "Rejected" && "text-darkred",
                          item.status === "Pending" && "text-textDisabled",
                        ].join(" ")}
                      >
                        {item.status}
                      </div>
                    </td>
                  </tr>
                )
              )}
              {/* Checkbox */}
            </table>
            {/* Orders List */}
            <div
              style={{
                height: "60px",
                borderBottom: "0.5px solid rgb(227 227 227)",
                display: "flex",
                alignItems: "center",
              }}
            >
              <div style={{ width: "50%" }}>
                <label
                  className="p-4"
                  onClick={() => setShowList(!showList)}
                  style={{
                    font: "normal normal normal 16px Manrope",
                    color: "#242424",
                    cursor: "pointer",
                    alignItems: "center",
                    display: "flex",
                  }}
                >
                  <input
                    type={"checkbox"}
                    value={showList}
                    onChange={() => {
                      setShowList(!showList);
                    }}
                    checked={showList}
                    style={{
                      height: "14px",
                      width: "14px",
                      marginRight: "11px",
                      cursor: "pointer",
                    }}
                    // defaultChecked={showList}
                  />
                  Hide Reject
                </label>
              </div>
              <div style={{ width: "50%" }}>
                <div className="col text-right px-6">
                  <button>Print the list</button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Popup>
      <ToastContainer />
    </>
  );
};

export default NewOrdersPopup;

{
  /* <NewOrder
                        name={item.productName}
                        sku={item.sku}
                        product_id={item.productId}
                        order_id={item._id}
                        day={item.day}
                      /> */
}

{
  /* <div className="row bg-primarylight bg-opacity-10 px-2 max-h-44 overflow-auto">
              {newOrdersData.map((items, i) => {
                return items.status != "Requested" ? (
                  !showList ? (
                    items.status !== "Rejected" && (
                      <div key={i} className="w-full">
                        <div className="row py-1">
                          <div className="col capitalize">{items.name}</div>
                          <div
                            className={[
                              "col text-center capitalize",
                              items.status === "Approved" && "text-success",
                              items.status === "Rejected" && "text-darkred",
                              items.status === "Pending" && "text-textDisabled",
                            ].join(" ")}
                          >
                            {items.status}
                          </div>
                        </div>
                      </div>
                    )
                  ) : (
                    <>
                      <div className="row py-1">
                        <div className="col capitalize">{items.name}</div>
                        <div
                          className={[
                            "col text-center capitalize",
                            items.status === "Approved" && "text-success",
                            items.status === "Rejected" && "text-darkred",
                            items.status === "Pending" && "text-textDisabled",
                          ].join(" ")}
                        >
                          {items.status}
                        </div>
                      </div>
                    </>
                  )
                ) : null;
              })}
            </div> */
}
