import React from "react";
import UserProfile from "../components/UserProfile";

const UserProfilePage = ()=>{
    return(
        <>
        <UserProfile />
        </>
    )
}
export default UserProfilePage;