import React from "react";
import CanvasJSReact from "./canvasjs.react";
//var CanvasJSReact = require('./canvasjs.react');
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

const ChartGraph = ({options})=>{
 CanvasJS.addColorSet("ThemeShade", [
   //colorSet Array
   "#6E62E3",
   "#E3E8FE",
 ]);
   
    return (
      <>
        <CanvasJSChart
          options={options}
          /* onRef = {ref => this.chart = ref} */
        />
      </>
    );
}

export default ChartGraph;