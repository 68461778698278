import React, { useEffect, useRef, useState } from "react";
import axios from "../../../API/AXIOS/axios";

const ListAccordion = ({ title, array }) => {
  return (
    <>
      <div className="px-2 listAccordion border">
        <div className="row listAccordion__outer">
          <div className="col">{title}</div>
          <div>+</div>
        </div>
        <div className="listAccordion__inner">
          {array.map((item, i) => {
            return (
              <>
                <div className="" key={i}>
                  <button className="px-2 py-1 w-full text-left">{item}</button>
                </div>
              </>
            );
          })}
        </div>
      </div>
    </>
  );
};

const DropDown = ({activeTab, disabled, gender, category, setCategory, catVal }) => {
  const dropDownOuter = document.getElementsByClassName("dropdown_menu")

  return (
    <>
      <div className="dropdown">
        <input
          className="dropdown__field capitalize"
          placeholder="Choose category"
          disabled={disabled}
          value={catVal}
          onFocus={()=>setCategory("")}
        />
        <div className="dropdown__menu">
          {category?.map((item,i)=>{
            return (
              <div key={i}>
                <div className="">
                  <button
                    onClick={() => {
                      setCategory(item);
                      localStorage.setItem("gender", gender);
                    }}
                    className="capitalize w-full text-left p-2"
                  >
                    {item}
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
};

export default DropDown;
