import React, { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../../context/AuthProvider";

const Header = () => {
  const [userData, setUserData] = useState();
  const navigate = useNavigate();
  const location = useLocation();
  const { setAuth } = useContext(AuthContext);
  const logout = async () => {
    setAuth({});
    navigate("/Login");
  };
  useEffect(() => {
    const user = localStorage.getItem("name");
    setUserData(user);
  }, []);
  console.log("userData", userData);
  return (
    <header className="bg-white">
      <div className="col_11 mx-auto">
        <div className="row items-center py-2">
          <button
            className="font-bold"
            onClick={() => {
              navigate("/" + location.search);
            }}
          >
            Retailor Portal
          </button>
          <div className="col text-right">
            <div className="row justify-end items-center cursor-pointer">
              <div
                className="hover:underline"
                onClick={() => {
                  navigate("/UserProfile" + location.search);
                }}
              >
                <b>Hi {userData},</b> Welcome Back
              </div>
              <div className="header__menu">
                <button
                  className="overflow-hidden rounded-full p-0.5 box-border hover:shadow-md"
                  onClick={() => {
                    navigate("/UserProfile" + location.search);
                  }}
                >
                  <img
                    src="/assets/images/boxes.png"
                    height={32}
                    width={32}
                    alt="userProfile"
                  />
                </button>
              </div>
              <div
                className="hover:underline"
                style={{ marginRight: "12px" }}
                onClick={() => {
                  localStorage.clear();

                  window.location.reload();
                }}
              >
                <b style={{ color: "#6E62E3" }}>Logout</b>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
