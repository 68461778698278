import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import axios from "../../API/AXIOS/axios";
import Button from "../CommonComponents/Buttons";
import InputField from "../CommonComponents/InputField";
import { useNavigate } from "react-router-dom";

const UserProfile = () => {
  let userData = localStorage.getItem("userData");
  console.log("userData from Backend", JSON.parse(userData));
  const initialValues = JSON.parse(userData);
  const submitForm = (e) => {
    console.log("form event", e);
  };
  let navigate = useNavigate();
  console.log("jwt_access_token", localStorage.getItem("jwt_access_token"));
  const updateUserDetails = (e) => {
    var myHeaders = new Headers();

    myHeaders.append(
      "Authorization",
      "Bearer " + localStorage.getItem("jwt_access_token")
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify(e);

    var requestOptions = {
      method: "PATCH",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      process.env.REACT_APP_BACKEND_BASE_URL_AFTER_LOGIN + "/user",
      requestOptions
    )
      .then((response) => response?.text())
      .then((res) => {
        console.log("responsresponsee2", res?.data, JSON.parse(res)?.data);
        let userData = JSON.stringify(JSON.parse(res)?.data);
        console.log("responsresponsee3", userData);
        localStorage.setItem("userData", userData);
        localStorage["name"] = JSON.parse(res)?.data.firstName;
        // JSON.parse(res)?.message === "Success."
        //   ? navigate("/")
        //   : navigate("/Login");
        // window.location.reload();
      })
      .catch((error) => console.log("error", error));
  };
  return (
    <>
      <div className=" bg-grey py-4 min-h-screen">
        <div className="col_11 mx-auto">
          <div>
            <h2 className="text-xl font-bold">Retailor Profile</h2>
          </div>
          <Formik
            initialValues={initialValues}
            validateOnBlur
            // validationSchema={validationSchema}
            onSubmit={(e) => {
              submitForm(e);
              updateUserDetails(e);
              // loginFormAuth(e);
            }}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              handleSubmit,
              isSubmitting,
              dirty,
            }) => (
              <Form onSubmit={handleSubmit}>
                <div className="py-4">
                  <div>
                    <h4 className="font-bold text-lg">Personal Information</h4>
                    <div className="row">
                      <div className="col_5">
                        <InputField
                          name={"firstName"}
                          placeholder={"Enter your name"}
                          label={"firstName"}
                          varient={"text"}
                          required
                          value={values?.firstName}
                          // touched
                          // dirty
                        />
                      </div>
                      <div className="col_5 px-2">
                        <InputField
                          name={"phone"}
                          placeholder={"Enter your mobile No."}
                          label={"Mobile No."}
                          varient={"tel"}
                          required
                          value={values?.phone}
                          // touched
                          // dirty
                        />
                      </div>
                      <div className="col_5 px-2">
                        <InputField
                          name={"email"}
                          placeholder={"Enter your email ID"}
                          label={"Email ID"}
                          varient={"mail"}
                          required
                          value={values?.email}
                          // touched
                          // dirty
                        />
                      </div>
                      <div className="col_5 px-2">
                        <InputField
                          name={"officeAddress"}
                          placeholder={"Enter Office Address"}
                          label={"Office Address"}
                          varient={"officeAddress"}
                          required
                          value={values?.address?.landmark}
                          // touched
                          // dirty
                        />
                      </div>
                      <div className="col_5 px-2">
                        <InputField
                          name={"pickupAddress"}
                          placeholder={"Enter Pickup Address."}
                          label={"Pickup Address"}
                          varient={"text"}
                          required
                          value={values?.pickup_address?.landmark}
                          // touched
                          // dirty
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <h4
                      className="font-bold text-lg"
                      style={{ marginTop: "30px" }}
                    >
                      Account Information
                    </h4>
                    <div className="row">
                      <div className="col_5">
                        <InputField
                          name={"accountNumber"}
                          placeholder={"Account Number"}
                          label={"Enter your account no."}
                          varient={"text"}
                          required
                          value={values?.bank_info?.account_number}
                          // touched
                          // dirty
                        />
                      </div>
                      <div className="col_5 px-2">
                        <InputField
                          name={"confirmAccountNumber"}
                          placeholder={"Re-enter account noumber"}
                          label={"Re-enter account noumber"}
                          varient={"text"}
                          required
                          value={values?.bank_info?.account_number}
                          // touched
                          // dirty
                        />
                      </div>
                      <div className="col_5 px-2">
                        <InputField
                          name={"ifscCode"}
                          placeholder={"Enter your IFSC CODE"}
                          label={"IFSC CODE"}
                          varient={"text"}
                          required
                          value={values?.bank_info?.ifsc}
                          // touched
                          // dirty
                        />
                      </div>
                      <div className="col_5 px-2">
                        <InputField
                          name={"accountType"}
                          placeholder={"Enter your Account type"}
                          label={"Acconunt Type"}
                          varient={"text"}
                          required
                          value={values?.registerType}
                          // touched
                          // dirty
                        />
                      </div>
                      {/* <div className="col_5 px-2">
                        <InputField
                          name={"panNumber"}
                          placeholder={"Enter your PAN No."}
                          label={"PAN No."}
                          varient={"text"}
                          required
                          value={values?.bank_info?.ifsc }
                          // touched
                          // dirty
                        />
                      </div> */}
                    </div>
                  </div>
                </div>
                <div className="py-6">
                  <Button title="Save" type="submit" />
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </>
  );
};

export default UserProfile;
