import React, { useRef, useState } from "react";
import Button from "../../CommonComponents/Buttons";
import * as xlsx from "xlsx";
import { json, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UploadFile = ({ file, setFile, disable }) => {
  const inputFileRef = useRef(null);
  const [fileData,setFileData] = useState()
  const navigate = useNavigate()
  const location = useLocation()

  const readFile = async (e) => {
    const myFile = e.target.files[0];
    e.preventDefault();
    if (e.target.files) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const data = e.target.result;
        const workbook = xlsx.read(data, { type: "array" });
        const sheetName = workbook.SheetNames[0];
        const worksheet = workbook.Sheets[sheetName];
        const jsonData = xlsx.utils.sheet_to_json(worksheet, {range:2, defval:"-"});
        setFileData(jsonData)
        localStorage.setItem("fileName", myFile.name);
        localStorage.setItem("uploadedFile", JSON.stringify(jsonData));
        if(jsonData.length > 0){
          jsonData && navigate("/UploadSheetReview" + location.search)
        } else {
          toast("File is Empty, Please upload a file with some data.",{ type:"error"})
        }
      };
        reader.readAsArrayBuffer(e.target.files[0]);
    }


  };  
  return (
    <>
      <Button
        type={"button"}
        title={"Upload Sheet"}
        custom={"w-full"}
        onClick={() => {
          inputFileRef.current.click();
        }}
        disable={disable}
      />
      <input
        type={"file"}
        ref={inputFileRef}
        onChange={(e) => {
          console.log("xlsx file", e.target.files[0]);
          readFile(e);
        }}
        className="hidden"
      />
      <ToastContainer />
    </>
  );
};

export default UploadFile;
