import React, { useState } from "react";

const Button = ({
  title,
  onClick,
  varient,
  type,
  custom,
  status,
  disable,
  tooltip,
  padding = "6px",
}) => {
  const [showTooltip, setShowTooltip] = useState(false);
  const classes = [
    "button",
    varient === "outlined" && "border bg-transparent",
    status === "success" && "button--success",
    status === "danger" && "button--danger",
    custom,
    "tooltip-button",
  ];
  return (
    <>
      <button
        style={{
          height: "35px",
          paddingLeft: "20px",
          paddingRight: "20px",
          paddingTop: padding,
          cursor: "pointer",
        }}
        className={classes.join(" ")}
        onClick={onClick}
        type={type}
        disabled={disable}
        // title={tooltip}
        onMouseEnter={() => {
          setShowTooltip(true);
        }}
        onMouseLeave={() => setShowTooltip(false)}
      >
        {title}
      </button>
      {showTooltip && tooltip ? <div className="tooltip">{tooltip}</div> : null}
    </>
  );
};

export default Button;
