import React from "react";
import { useLocation, useNavigate } from "react-router-dom";

const Card = ({ onClick, path, varient, ...props }) => {
  const classes = ["card", varient === "light" && "bg-primarylightest"];
  const navigate = useNavigate();
  const location = useLocation();
  return (
    <>
      <div
        className={classes.join(" ")}
        onClick={() => {
          path && navigate(path + location.search);
          onClick && onClick();
        }}
      >
        {props.children}
      </div>
    </>
  );
};
export default Card;
