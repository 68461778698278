import React, { useState } from "react";
import Button from "../CommonComponents/Buttons";
import Card from "../CommonComponents/Card";
import NewOrdersPopup from "../Popups/NewOrdersPopup";

const Cards = ({
  newOrders,
  noOfItems,
  pickupSchedule,
  lastWeek,
  lastMonth,
  excelCount,
}) => {
  const [closeNewOrdersPopup, setCloseNewOrdersPopup] = useState(false);

  return (
    <>
      <div className="row items-stretch">
        <div className="card__container">
          <Card onClick={() => setCloseNewOrdersPopup(true)}>
            <div className="row  font-bold items-center">
              <h4 className="col">New Orders</h4>
              <div>
                <div className="row items-center">
                  <div>
                    <img src="/assets/images/bell.svg" alt="notification" />
                  </div>
                  <div className="rounded-full bg-white text-primary w-6 h-6 text-center">
                    {newOrders}
                  </div>
                </div>
              </div>
            </div>
            <table className="w-full">
              <tr>
                <td className="py-2.5">No. Of Items</td>
                <td className="text-right font-bold">{noOfItems}</td>
              </tr>
              <tr>
                <td className="py-2.5">Pickup Schedule</td>
                <td className="text-right font-bold">{pickupSchedule}</td>
              </tr>
            </table>
          </Card>
        </div>
        <div className="card__container">
          <Card varient={"light"} path={"/PreviousOrders"}>
            <div className="row  font-bold items-center">
              <h4 className="col">Previous Order</h4>
            </div>
            <table className="w-full">
              <tr className="items-center">
                <td className="py-2.5">Last Week</td>
                <td className="font-bold py-2.5">{lastWeek}</td>
                <td className="text-right font-bold">
                  <Button
                    title={`Approved ${50}`}
                    custom="bg-white text-success font-normal py-1.5 my-1 w-36 text-sm"
                  />
                </td>
              </tr>
              <tr className="items-center">
                <td className="py-2.5">Last Month</td>
                <td className="font-bold py-2.5">{lastMonth}</td>
                <td className="text-right font-bold">
                  <Button
                    title={`Rejected ${50}`}
                    custom="bg-white text-red font-normal py-1.5 my-1 w-36 text-sm"
                  />
                </td>
              </tr>
            </table>
          </Card>
        </div>
        <div className="card__container">
          <Card varient={"light"} path={"/QCList"}>
            <div className="row  font-bold items-center">
              <h4 className="col">Quality Check</h4>
            </div>
            <table className="w-full">
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  gap: "50px",
                }}
              >
                <tr
                  className="items-center"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                >
                  <td className="py-2.5">Pending </td>
                  <td className="font-bold py-2.5">
                    {excelCount?.Pending || 0}
                  </td>
                </tr>
                <tr
                  className="items-center"
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <td className="py-2.5">Rejected</td>
                  <td className="font-bold py-2.5">
                    {excelCount?.Rejected || 0}
                  </td>
                </tr>
              </div>
              <div style={{ width: "50%" }}>
                <tr
                  className="items-center"
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "85%",
                  }}
                >
                  <td className="py-2.5">Accepted</td>
                  <td className="font-bold py-2.5">
                    {excelCount?.Approved || 0}
                  </td>
                </tr>
              </div>
            </table>
          </Card>
        </div>
        {closeNewOrdersPopup && (
          <NewOrdersPopup closePopup={() => setCloseNewOrdersPopup(false)} />
        )}
      </div>
    </>
  );
};

export default Cards;
