import HomePage from "./components/LandingPage";
import NewListing from "./components/NewListing";
import UploadedList from "./components/NewListing/UploadFile.js/UploadedList";
import PaymentPage from "./pages/PaymentPage";
// import NewListing from "../components/NewListing";
// import Uploaded from "./Uploaded";
import PreviousOrdersPage from "./pages/PreviousOrders";
import QCListPage from "./pages/QCList";
import Uploaded from "./pages/Uploaded";
import UserProfilePage from "./pages/UserProfile";
// import PreviousOrdersPage from "./PreviousOrders";

export const PrivateRoutes = [
  {
    name: "HOMEPAGE",
    path: "/",
    component: <HomePage />,
  },
  {
    name: "NEWLISTING",
    path: "/NewListing",
    component: <NewListing />,
  },
  {
    name: "UPLOADED",
    path: "/Uploaded",
    component: <Uploaded />,
  },
  {
    name: "PREVIOUSORDER",
    path: "/PreviousOrders",
    component: <PreviousOrdersPage />,
  },
  {
    name: "USERPROFILE",
    path: "/UserProfile",
    component: <UserProfilePage />,
  },
  {
    name: "PAYMENT",
    path: "/Payment",
    component: <PaymentPage />,
  },
  {
    name: "UPLOADSHEETREVIEW",
    path: "/UploadSheetReview",
    component: <UploadedList />,
  },
  {
    name: "QCLIST",
    path: "/QCList",
    component: <QCListPage />,
  },
];
