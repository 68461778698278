import { Form, Formik } from "formik";
import React, { useState } from "react";
import Popup from "../NewListing";
import Button from "../CommonComponents/Buttons";
import InputField from "../CommonComponents/InputField";
import {fileData} from "../NewListing/UploadFile.js/index"

const UploadedListPopup = ({
  productName,
  brandName,
  category,
  price,
  closePopup,
  arrayData
}) => {
  console.log("array in upload list",arrayData)
  const initialValues = arrayData;
  return (
    <>
        {/* <Formik
          initialValues={initialValues}
          onSubmit={(e) => {
            console.log("values from fromik", e);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
            dirty,

          }) => (
            <Form onSubmit={handleSubmit}>
              {arrayData?.map((item, i) => {
                return (
                  <div className="border-b-2 border-darkgrey max-h-40">
                    <div>
                      <InputField
                        name={item.productName}
                        placeholder={""}
                        label={"hello"}
                        varient={"text"}
                        required
                      />
                      {item.productName + "hello"}
                    </div>
                    <div>
                      <div className="h-16 w-16">
                        <img
                          src="/assets/images/boxes.png"
                          className="w-full h-full"
                        />
                      </div>
                    </div>
                    <div>{item.productBrand}</div>
                    <div>{item.category}</div>
                    <div>Rs. {item.productPrice}</div>
                    <div>M, XL</div>
                    <div>
                      <div className="row justify-evenly">
                        <button onClick={() => alert("edit")}>Edit</button>
                        <button onClick={() => alert("del")}>Del</button>
                      </div>
                    </div>
                  </div>
                );
              })}
              <div>
                <Button title="Submit" type="submit" />
              </div>
            </Form>
          )}
        </Formik> */}
        <table className="w-full text-left bg-white popupTable">
          <thead>
            <th>File Name</th>
            <th>Product</th>
            <th>Brand</th>
            <th>Category</th>
            <th>Price</th>
            <th>Size</th>
            <th>Action</th>
          </thead>
          <tbody>
            {
              arrayData?.map((item,i)=>{
                return (
                  <tr className="border-b-2 border-darkgrey">
                    <td>{item.productName}</td>
                    <td>
                      <div className="h-16 w-16">
                        <img
                          src={item.productImageUrl}
                          className="w-full h-full"
                        />
                      </div>
                    </td>
                    <td>{item.productBrand}</td>
                    <td>{item.category}</td>
                    <td>Rs. {item.productPrice}</td>
                    <td>M, XL</td>
                    <td>
                      <div className="row justify-evenly">
                        <button onClick={() => alert("edit")}>Edit</button>
                        <button onClick={() => alert("del")}>Del</button>
                      </div>
                    </td>
                  </tr>
                );
              })
            }
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={7} className="text-center w-full py-2">
                <Button title={"Submit"} onClick={() => alert("submit")} />
              </td>
            </tr>
          </tfoot>
        </table>
    </>
  );
};

export default UploadedListPopup;
