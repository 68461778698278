import React from "react";
import UploadedPage from "../components/Uploaded";

const Uploaded =()=>{
    return(
        <>
        <UploadedPage />
        </>
    )
}

export default Uploaded;