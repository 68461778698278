import moment from "moment/moment";
import React, {useState, useEffect} from "react";
import { useLocation, useNavigate } from "react-router-dom";

const PaymentTable = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const token = localStorage.getItem("jwt_access_token");
  let paymentUrl = `/payments/getAll`;
  const [paymentData, setPaymentData] = useState([]); useEffect(() => {
    getPaymentsAndStatus()
  }, [])

  const getPaymentsAndStatus = () => {
    var requestOptions = {
      method: "get",
      headers: { Authorization: `Bearer ${token}` },
    };
    fetch(process.env.REACT_APP_BACKEND_BASE_URL_AFTER_LOGIN + paymentUrl, requestOptions)
      .then((response) => response.json())
      .then((res) => {
        if (res.status) {
          setPaymentData(res.data);
        }
      });
  }
  return (
    <>
      <table className="w-full bg-white rounded-lg text-left overflow-hidden primarytable">
        <thead className="border-b-2 border-darkgrey">
          <tr>

            <th>Month</th>
            <th>Total Billing</th>
            <th>Payment Received</th>
            <th>Next Payment due</th>
          </tr>
        </thead>
        <tbody>
          {paymentData.length > 0 && paymentData.map((item, i) => {
            if(i>3){
              return;
            }
            let paymentReceived = 0;
            let paymentReceivedCount = 0
            let paymentPending = 0;
            let lastDue = "";
            item?.payments?.map((payment) => {
              if (payment?.status == "Completed") {
                paymentReceived = paymentReceived + (item?.totalAmount * payment?.percent / 100);
                paymentReceivedCount = paymentReceivedCount + 1;
              } else if (payment?.status == "Pending") {
                paymentPending = paymentPending + (item?.totalAmount * payment?.percent / 100);
                if (lastDue == "") {
                  lastDue = payment?.date
                }
              }
            });
            return (
              <tr className="border-b border-lightgrey">
                <td>{item?.month}</td>
                <td>Rs. {item?.totalAmount}</td>
                <td>Rs. {paymentReceived}</td>
                <td>{lastDue && moment(lastDue).format("DD MMM' YYYY")}</td>
              </tr>
            )
          })}
        </tbody>
        <tfoot className="bg-darkgrey">
          <tr>

            <td></td>
            <td></td>
            <td></td>
            <td>
              <button className=""
                onClick={() => navigate("/Payment" + location.search)}
              >
                <div className="row items-center">
                  Show All
                  <div className="rounded-full bg-primarylightest text-white w-7 h-7 mx-2">
                    {">"}
                  </div>
                </div>
              </button>
            </td>
          </tr>
        </tfoot>
      </table>
    </>
  );
}

export default PaymentTable;