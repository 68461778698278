import React from "react";
import Header from "../CommonComponents/Header";
import PreviousOrdersTable from "./PreviousOrdersTable";

const PreviousOrders = () => {
  return (
    <>
      <div className="bg-grey min-h-screen">
        {/* <Header /> */}
        <div className="col_11 mx-auto">
          <div className="row py-4 items-center">
            <div className="col">
              <h2 className="text-xl font-bold">Previous Orders</h2>
            </div>
            <div>
              Sort By
              <select className="bg-white rounded-lg p-2 outline-none border-none ml-4 font-bold">
                <option>Brand</option>
              </select>
              <select className="bg-white rounded-lg p-2 outline-none border-none ml-4 font-bold">
                <option>Time Period</option>
              </select>
            </div>
          </div>
          <PreviousOrdersTable />
        </div>
      </div>
    </>
  );
};

export default PreviousOrders;
